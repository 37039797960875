<template>
  <v-card
    class="mx-auto"
    width=100%
    height=100%
    flat
    tile
  >
     <v-toolbar
      color="indigo"
      dark
    >
      <v-btn @click="onback"  icon>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-1">{{name}}</v-toolbar-title>
      <v-spacer></v-spacer>
      
    </v-toolbar>
 



  </v-card>
</template>

<script>

  export default {
    

    data: () => ({
        name: 'Play',  
    }),
    created() {
       console.log("Play created   "+this.$route.params.serno);
      
      //
    },
    mounted(){
        console.log("Play mounted   ");
    },
    destroyed() {
        console.log("Play destroyed   ");
    },
    methods: {
       onback: function(){
            
           this.$router.push("/playlist")
      }
  
    },
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
